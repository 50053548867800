"use client";

import React, { useState, useEffect, FC } from "react";
import Link from "next/link";
import { UserMenu } from "@/lib/domains/user.interface";
import { cn } from "@/lib/utils";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import useUserStore from "@/lib/stores/user.store";
import { usePathname } from "next/navigation";
import {
  ChartLine,
  ChartBar,
  DollarSign,
  Files,
  Lock,
  Users,
  Notebook,
  PanelsTopLeft,
} from "lucide-react";

const SidebarIcons = (itemName: string) => {
  switch (itemName) {
    case "Maestros Contables":
      return <DollarSign className="text-gray-400 w-5 h-5" />;
    case "Personal":
    case "Usuarios":
      return <Users className="text-gray-400 w-5 h-5" />;
    case "Proyectados":
      return <ChartLine className="text-gray-400 w-5 h-5" />;
    case "Forecast":
      return <ChartBar className="text-gray-400 w-5 h-5" />;
    case "Seguridad":
    case "Permisos":
      return <Lock className="text-gray-400 w-5 h-5" />;
    case "Reportes":
      return <Files className="text-gray-400 w-5 h-5" />;
    case "Roles":
      return <Notebook className="text-gray-400 w-5 h-5" />;
    case "Menu":
      return <PanelsTopLeft className="text-gray-400 w-5 h-5" />;
    default:
      return <DollarSign className="text-gray-400 w-5 h-5" />;
  }
};

const SidebarMenu: FC = () => {
  const pathnames = usePathname();
  const { menus } = useUserStore();
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  useEffect(() => {
    const expandedIds: string[] = [];

    menus.forEach((menu) => {
      if (menu.subItems) {
        const isActive = menu.subItems.some(
          (subItem) => subItem.url === pathnames
        );
        if (isActive) {
          expandedIds.push(menu.id);
        }
      }
    });

    if (expandedIds.length === 0) {
      const firstMenuWithSubItems = menus.find((menu) => menu.subItems);
      if (firstMenuWithSubItems) {
        expandedIds.push(firstMenuWithSubItems.id);
      }
    }

    setExpandedItems(expandedIds);
  }, [pathnames, menus]);

  const handleToggle = (id: string) => {
    setExpandedItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const renderMenuItems = (items: UserMenu[], depth = 0) => {
    return items.map((item) => {
      const hasSubItems = item.subItems && item.subItems.length > 0;

      if (depth === 0) {
        if (hasSubItems) {
          return (
            <AccordionItem key={item.id} value={item.id}>
              <AccordionTrigger
                onClick={() => handleToggle(item.id)}
                className="flex items-center justify-between w-full py-2 decoration-none"
              >
                <div className="flex items-center gap-2">
                  {SidebarIcons(item.name)}
                  <span>{item.name}</span>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <div className="pl-4 space-y-1">
                  {renderMenuItems(item.subItems!, depth + 1)}
                </div>
              </AccordionContent>
            </AccordionItem>
          );
        } else {
          return (
            <div key={item.id}>
              <Link href={item.url!}>
                <div
                  className={cn(
                    "flex items-center gap-2 pl-4 pr-2 py-2 hover:bg-gray-700 rounded-lg cursor-pointer",
                    pathnames === item.url && "bg-gray-700"
                  )}
                >
                  {SidebarIcons(item.name)}
                  <span>{item.name}</span>
                </div>
              </Link>
            </div>
          );
        }
      } else {
        if (hasSubItems) {
          return (
            <AccordionItem key={item.id} value={item.id}>
              <AccordionTrigger
                onClick={() => handleToggle(item.id)}
                className="flex justify-between w-full py-2 decoration-none pl-4"
              >
                <span>{item.name}</span>
              </AccordionTrigger>
              <AccordionContent>
                <div className="pl-4">
                  {renderMenuItems(item.subItems!, depth + 1)}
                </div>
              </AccordionContent>
            </AccordionItem>
          );
        } else {
          return (
            <div key={item.id}>
              <Link href={item.url!}>
                <span
                  className={cn(
                    "block pl-8 pr-2 py-2 hover:bg-gray-700 rounded-lg cursor-pointer",
                    pathnames === item.url && "bg-gray-700"
                  )}
                >
                  {item.name}
                </span>
              </Link>
            </div>
          );
        }
      }
    });
  };

  return (
    <div className="w-64 bg-gray-800 text-white h-screen overflow-auto px-3">
      <Accordion
        type="multiple"
        value={expandedItems}
        onValueChange={setExpandedItems}
      >
        {renderMenuItems(menus)}
      </Accordion>
    </div>
  );
};

export default SidebarMenu;
