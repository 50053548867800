"use client";

import React from 'react';
import Link from "next/link";
import Image from "next/image";
import { Avatar, AvatarFallback } from './ui/avatar';
import {
  Bell,
  LayoutGrid,
  Sun
} from "lucide-react"
import { Button } from './ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from './ui/dropdown-menu';
import { logout } from '@/actions/logout';
import useUserStore from '@/lib/stores/user.store';

const Navbar = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const { user } = useUserStore();

  const onHandleLogout = () => {
    logout()
    
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div className="w-full h-full overflow-hidden">
      <nav className="bg-gray-800 p-4 fixed top-0 left-0 w-full h-[66px]">
        <div className="w-full mx-auto flex items-center justify-between h-8">
          <div className="flex items-center">
            <Link href="/" className="flex items-center gap-2">
              <Image src="/images/logo.png" alt="CODEA +" width={64} height={64} className="w-8 h-8" />
              <span className="text-2xl font-bold">CODEA +</span>
            </Link>
          </div>
          <div className="flex items-center space-x-2">
            <Button variant="secondary" size="icon" className="ml-auto h-8 w-8">
              <Bell className="text-gray-400 w-5 h-5 font-bold" />
            </Button>
            <Button variant="secondary" size="icon" className="ml-auto h-8 w-8">
            <LayoutGrid className="text-gray-400 w-5 h-5 font-bold" />
            </Button>
            <Button variant="secondary" size="icon" className="ml-auto h-8 w-8">
              <Sun className="text-gray-400 w-5 h-5 font-bold" />
            </Button>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="secondary"
                  size="icon"
                  className="overflow-hidden rounded-full"
                >
                  <Avatar>
                    <AvatarFallback>{user.name.charAt(0).toUpperCase()}{user.lastname.charAt(0).toUpperCase()}</AvatarFallback>
                  </Avatar>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Mi cuenta</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem>Settings</DropdownMenuItem>
                <DropdownMenuItem>Support</DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={onHandleLogout}>Cerrar sesión</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            
          </div>
        </div>
      </nav>

      {children}
    </div>
  );
};

export default Navbar;
